<template>
  <div>
    <header>
      <div class="container">
        <h1>Dashboard</h1>
        <div class="tabs is-danger">
          <ul>
            <li
              v-for="option in sortOptions"
              :key="option.title" 
              :class="{ 'is-active': selectedSortOption === option.to.name }"
            >
              <a class="button discussions-button" @click="$router.push(option.to);">
                <b-icon :icon="option.icon" :custom-class="option.customClass" />
                <span class="button-label">{{ option.title }}</span>
              </a>
            </li>
          </ul>
        </div>
        <div class="user-links">
          <div class="user">
            <p>{{ $auth.user | user_name }}</p>
            <p>
              <router-link
                :to="{
                  name: 'user_profile',
                  params: { username: $auth.user.username }
                }"
              >
                View Public Profile
              </router-link>
            </p>
          </div>
          <figure>
            <Avatar
              v-if="!$auth.loading && $auth.isAuthenticated"
              :seed-string="$auth.user | user_name"
              :color="$auth.user.avatar_color"
              size="medium"
            />
          </figure>
        </div>
      </div>
    </header>
    <div class="container">
      <hr class="hr" />
      <transition name="fade">
        <keep-alive>
          <router-view @updated="onUpdated" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/common/Avatar.vue";
export default {
  name: "Dashboard",
  components: {
    Avatar
  },
  data() {
    return {
      sortOptions: [
        //{ #1011 Temporarily Hide Prompts BEGIN
          //title: "Activity",
          //to: { name: "dashboard" },
          //icon: "grip-vertical"
        //}, #1011 Temporarily Hide Prompts END
        {
          title: "Inbox",
          to: { name: "dashboard_inbox" },
          icon: "envelope"
          // customClass: "fa-rotate-90",
          // where: null,
          // orderBy: { available_after: "desc" }
        },
        {
          title: "Events",
          to: { name: "dashboard_events" },
          icon: "calendar"
        },
        {
          title: "Challenges",
          to: { name: "dashboard_challenges" },
          icon: "flag"
        },
        //{ #1011 Temporarily Hide Prompts BEGIN
          //title: "Prompts",
          //to: { name: "dashboard_prompts" },
          //icon: "sticky-note"
        //}, #1011 Temporarily Hide Prompts END
        {
          title: "Settings",
          to: { name: "dashboard_profile" },
          icon: "user"
        }
      ]
    };
  },
  computed: {
    selectedSortOption() {
      return this.$route.name;
    }
  },
  methods: {
    onUpdated() {
      this.$log.info("child updated");
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../scss/mixins";
header {
  padding: 30px 0 0 0;
  @include mobile {
    padding: vwl(30px) 0 vwl(5px) 0;
    flex-direction: column;
    align-items: center;
  }
  @include portrait {
    padding: vwp(30px) 0 vwp(5px) 0;
  }
  @include widescreen {
    padding: 45px 0 0 0;
  }
  > .container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
  }
  p {
    color: $grey900;
    font-size: 16px;
    margin: 0;
    line-height: 1.2;
    @include mobile {
      font-size: vwl(12px);
    }
    @include portrait {
      font-size: vwp(12px);
    }
    // @include desktop {
    //   font-size: 16px;
    // }
    @include widescreen {
      font-size: 18px;
    }
    a {
      font-size: 14px;
      color: $blue;
      border-bottom: solid 1px transparent;
      transition: .2s;
      @include mobile {
        font-size: vwl(10px);
      }
      @include portrait {
        font-size: vwp(10px);
      }
      @include widescreen {
        font-size: 16px;
      }
      &:hover,
      &:focus {
        border-bottom-color: $blue;
      }
    }
  }
  .tabs {
    @include mobile {
      margin-top: vwl(5px);
    }
    @include portrait {
      order: 3;
      margin-top: vwp(15px);
    }
    ul {
      @include portrait {
        align-items: center;
      }
      @include from($portrait + 1px) {
        justify-content: left;
      }
      @include tablet {
        column-gap: 0.5rem;
      }
    }
  }
  .button {
    color: $grey900;
    transition: .2s;
    @include desktop {
      background: #f1f1f1;
      border: none;
      &:hover,
      &:focus {
        background-color: $gold;
        box-shadow: inset 0 2px 4px 0 rgb(33 35 48 / 20%)
      }
    }
    span {
      font-weight: 400;
      @include tablet {
        font-size: 18px;
      }
      @include desktop {
        font-size: 16px;
      }
      @include widescreen {
        font-size: 18px;
      }
      &.icon {
        @media (min-width: $tablet) and (max-width: ($desktop - 1px)) {
          width: 0.75em;
        }
        &:first-child:not(:last-child) {
          @include until($desktop) {
            margin: 0;
          }
        }
      }
      &.button-label {
        @include until($desktop) {
          font-size: 0;
        }
      }
    }
  }
}
.container {
  padding: 0 0.5em;
  @include until($desktop) {
    width: 100%;
    max-width: 984px;
  }
}
h1 {
  color: $grey900;
  @include portrait {
    order: 1;
  }
  @include from($portrait + 1px) {
    flex-basis: 100%;
    margin-bottom: vwl(15px);
  }
  @include tablet {
    margin-top: 5px;
  }
}
.user-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  @include mobile {
    flex-grow: 0;
    margin-top: vwl(5px);
  }
  @include portrait {
    order: 2;
    margin-top: vwl(5px);
  }
  .user {
    display: flex;
    flex-direction: column;
    text-align: right;
  }
  figure {
    margin-left: 1rem;
    @include portrait {
      display: none;
    }
  }
}
.b-tabs, .tabs {
  ul {
    justify-content: center;
    @include tablet {
      border-bottom: none;
      justify-content: left;
    }
  }
  &.is-danger {
    li {
      a {
        margin: 0;
        &:hover,
        &:focus {
          @include until($desktop) {
            box-shadow: inset 0 2px 4px 0 rgb(33 35 48 / 20%);
            background-color: $pink;
            border: solid 1px shade($pink, 20%);
          }
        }
      }
      &.is-active {
        @include until($desktop) {
          box-shadow: none;
        }
        a {
          border-bottom-color: $pink;
          @include until($desktop) {
            color: $grey900;
            background-color: $gold;
            border-color: transparent;
            font-weight: 500;
            box-shadow: inset 0 2px 4px 0 rgb(33 35 48 / 20%);
          }
          @include desktop {
            color: $pink;
            border-bottom: none;
            &:hover,
            &:focus {
              background-color: $pink;
              color: $white;
            }
          }
        }
      }
    }
  }
}
.next-event {
  .date-square {
    .time, 
    .day {
      font-size: 0;
    }
  }
}

.hr {
  background-color: $yellow;
  margin-top: 7px;
}

</style>
